import React from 'react';
import Layout from '../components/Layout';

const rowPadding = {
  padding: "150px 0"
}

export default function Careers() {
  return (
    <Layout>
      <section className='w-100 careers-bg' style={{marginTop: '74px'}}>
        <div className='container'>
          <div className='row align-items-center' style={rowPadding}>
            <div className='col-md-6 text-white'>
              <h1 className='display-5 text-capitalize'>Build your career with us</h1>
              <p className='fs-5'>Together we can do so much. We are always eager to meet new talents to add in our team, please check our open positions.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row justify-content-center' style={rowPadding}>
          <h2 className='display-5 text-center'>Gallery</h2>
              <div class="col-md-4 mt-3 col-lg-4">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 1"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-4">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 2"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-4">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 3"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-6">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 4"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-6">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 5"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-3">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 6"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-3">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 7"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-3">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 8"/>
              </div>
              <div class="col-md-4 mt-3 col-lg-3">
                  <img src="https://source.unsplash.com/collection/190726/1500x900" class="img-fluid" alt="GoogolWeb 9"/>
              </div>

              <div class="d-grid gap-2 d-md-flex justify-content-md-center mt-5">
                <button class="btn btn-primary" type="button">See More</button>
              </div>
        </div>
      </section>

      <section className='w-100 bg-white text-dark'>
        <div className='container'>
          <div className='row' style={rowPadding}>
            <h2 className='display-5 text-capitalize mb-4'>Open positions:</h2>
            <div className='col-md-12'>
              <div class="alert alert-primary d-flex align-items-center p-5" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <h5 className='m-0'>
                  We have no available positions right now. Please come back soon.
                </h5>
              </div>
            </div>
          </div> 
        </div>
      </section>

      <section className='container border-bottom'>
        <div className='row' style={{padding: "100px 0 "}}>
          <div className='col-md-6'>
            <h2 className='display-5'>Can't find a position that suits your skill? Maybe we can!</h2>
          </div>
          <div className='col-md-6'>
            <p  className='fs-4'>
              We can always make room for talented people. Submit your CV and portfolio. We’ll be in touch if anything comes up.
            </p>
            <button type='button' className='btn btn-orange'>Send us you updated CV</button>
          </div>
        </div>
      </section>
    </Layout>
  );
}
